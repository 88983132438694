// ** Axios Imports
import axios from 'axios'

import configExternalApi from '../configExternal'
const environment = process.env.REACT_APP_STAGE
const headerProfileBack = configExternalApi[environment].headerProfileBack
const headerProfileBackB = configExternalApi[environment].headerProfileBackB
const headerOmnisysIntegracion = configExternalApi[environment].headerOmnisysIntegracion

export const getUserByEmail = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlUsersExternal}?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data.information
    } catch (e) {
        return false
    }
}

export const getUserCourses = async (email) => {
    try {
        let response = await axios.get(
          `${configExternalApi[environment].apiProfileBackIntegrationsB}/customers-courses-progress-v2?email=${email}`,
          {
            headers: {
              "content-type": "application/json",
              "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
          }
        )
        console.log("getUserCourses", response)
        return response.data
    } catch (e) {
        return []
    }
}

export const getUserRoutes = async (email) => {
    try {
        let response = await axios.get(
          `${configExternalApi[environment].apiProfileBackIntegrationsB}/customers-learning-paths-progress-v2?customerIdentifier=${email}`,
          {
            headers: {
              "content-type": "application/json",
              "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
          }
        )
        console.log("getUserRoutes", response)
        return response.data
    } catch (e) {
        return []
    }
}

export const getUserStatisticsCourses = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlCoursesExternal}-statistics?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        return {
            all: 0,
            finished: 0,
            inProgress: 0,
            toStart: 0
        }
    }
}

export const getUserMentoring = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlMentoringExternal}?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        return {
            soonMentoring: [],
            recordMentoring: [],
            totalMentoringSessions: 0,
            mentoringSessionsAvailable: 0
        }
    }
}

export const getUserHobbies = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlHobbiesExternal}?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })

        return response.data.information

    } catch (e) {
        return {}
    }
}

export const addUserHobbies = async (email, hobbies) => {
    try {
        let response = await axios.put(`${configExternalApi[environment].apiUrlHobbiesExternal}?customerIdentifier=${email}`, {
            information: hobbies
        }, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })

        return response.data.information

    } catch (e) {
        return {}
    }
}

export const addUserInformation = async (email, user) => {
    try {
        let response = await axios.put(`${configExternalApi[environment].apiUrlUsersExternal}?customerIdentifier=${email}`, {
            information: user
        }, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })

        return response.data.information

    } catch (e) {
        return false
    }
}

export const getUserStatisticsJobs = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlJobsExternal}-statistics?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        return {
            all: 0,
            active: 0,
            inProgress: 0,
            hired: 0
        }
    }
}

export const getUserPreferences = async (email) => {
    //console.log(email)
    let response = await axios.get(`${configExternalApi[environment].apiUrlPreferencesExternal}?customerIdentifier=${email}`, {
        headers: {
            'content-type': 'application/json',
            "Ocp-Apim-Subscription-Key": headerProfileBack
        }
    })
    return response.data.information
}

export const addUserPreferences = async (email, preferences) => {
    try {
        let response = await axios.put(`${configExternalApi[environment].apiUrlPreferencesExternal}?customerIdentifier=${email}`, {
            information: {
                preferences
            }
        }, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data.information.preferences
    } catch (e) {
        return []
    }
}

export const getImagesDefault = async () => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlImagesDefaultExternal}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data[0].files
    } catch (e) {
        return []
    }
}

export const uploadCustomerDefault = async (file, user) => {
    try {


        let response
        if (user.fileId) {
            let newImage = {
                ownerId: user.email,
                size: file.size,
                extension: file.extension,
                file: file.file
            }
            response = await axios.put(`${configExternalApi[environment].apiUrlCustomerImagesExternal}?fileId=${user.fileId}`, newImage, {
                headers: {
                    'content-type': 'application/json',
                    "Ocp-Apim-Subscription-Key": headerProfileBack
                }
            })
        } else {
            let newImage = {
                ownerId: user.email,
                file
            }
            response = await axios.post(`${configExternalApi[environment].apiUrlCustomerImagesExternal}`, newImage, {
                headers: {
                    'content-type': 'application/json',
                    "Ocp-Apim-Subscription-Key": headerProfileBack
                }
            })
        }

        return response.data
    } catch (e) {
        return false
    }
}

export const uploadCustomerResume = async (file, user) => {
    try {
        let response
        if (user.fileId) {
            let newFile = {
                ownerId: user.email,
                size: file.size,
                extension: file.extension,
                file: file.file
            }
            response = await axios.put(`${configExternalApi[environment].apiUrlCustomerResumeExternal}?fileId=${user.fileId}`, newFile, {
                headers: {
                    'content-type': 'application/json',
                    "Ocp-Apim-Subscription-Key": headerProfileBack
                }
            })
        } else {
            let newFile = {
                ownerId: user.email,
                file
            }
            response = await axios.post(`${configExternalApi[environment].apiUrlCustomerResumeExternal}`, newFile, {
                headers: {
                    'content-type': 'application/json',
                    "Ocp-Apim-Subscription-Key": headerProfileBack
                }
            })
        }
        return response.data
    } catch (error) {
        return false
    }
}

export const getCustomerResume = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlCustomerResumeExternal}?ownerId=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) { }
}

export const deleteCustomerResume = async (email) => {
    try {
        let response = await axios.delete(`${configExternalApi[environment].apiUrlCustomerResumeExternal}?ownerId=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) { }
}

export const getProfileImageUser = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlCustomerImagesExternal}?ownerId=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data.files[0]
    } catch (e) {
        return false
    }
}

export const getAchievementsUser = async (email, type) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlAchievementsExternal}?email=${email}&clasification=${type}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        return []
    }
}

export const getTotalAchievementsUser = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlAchievementsExternal}-count?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        return {
            acquired: 0,
            available: 0
        }
    }
}

export const deleteProfileImageUser = async (email) => {

    try {
        let response = await axios.delete(`${configExternalApi[environment].apiUrlCustomerImagesExternal}?ownerId=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        //console.log("response delete", response)
        return response.data
    } catch (e) {
        return false
    }
}

export const getUserMentoringStatistics = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlMentoringExternal}-statistics?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        return {
            mentoringAvailable: 0
        }
    }
}

export const getUserMonthlyMentoring = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlUserMonthlyMentoring}?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        return {
            weeklyMentoring: [],
            monthlyMentoring: []
        }

    }
}

export const getUserMentorsInfo = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/clients-information-mentoring-mentors-historical?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        return []
    }

}
export const getUserMentorsInfoB2B = async (email) => {
    try {
        let response = await axios.get(
            `${configExternalApi[environment].apiProfileBackIntegrations}/clients-information-mentoring?email=${email}`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBack
                }
            }
        )
        return response.data
    } catch (error) {
        return []
    }

}


export const getNewCourseLiderly = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrationsB}/learning-hub-recent-courses?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })
        return response.data
    } catch (e) {
        return []
    }
}
export const getNewRoutesLiderly = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrationsB}/learning-hub-recent-learning-paths?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })
        return response.data
    } catch (e) {

        return []

    }
}

export const saveNewCourseOrPath = async (email, idElement) => {
    let body = {
        email,
        resourceId: idElement
    }

    try {
        let response = await axios.post(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-interest-common-item?email=${email}`,
            body, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        return {
            mentoringAvailable: 0
        }
    }
}

export const saveMostAcquiredCourseOrPath = async (email, idElement) => {
    let body = {
        email,
        resourceId: idElement
    }

    try {

        let response = await axios.post(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-interest-common-item?email=${email}`,
            body, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        return {
            mentoringAvailable: 0
        }
    }
}

export const deleteNewCourseOrPath = async (email, idElement) => {
    let body = {
        email,
        resourceId: idElement
    }
    //console.log(body)
    try {
        let response = await axios.put(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-interest-common-item`,
            body, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        return {

        }
    }
}

export const deleteMostAcquiredCourseOrPath = async (email, idElement) => {
    let body = {
        email,
        resourceId: idElement
    }

    try {
        let response = await axios.put(`${configExternalApi[environment].apiProfileBackIntegrationsB}/learning-hub-recent-learning-paths`, body, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })
        return response.data
    } catch (e) {
        return {
            mentoringAvailable: 0
        }
    }
}

export const getMetricasUser = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-products-metrics?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        return {
            "totalCourses": 0,
            "totalLearningPaths": 0,
            "totalMentoring": 0,
            "totalApplicationsJobs": 0
        }
    }
}

export const getSkills = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-current-skills?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        return []
    }
}

export const getCustomersSocialMedia = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlCustomersSocialMedia}?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })

        return response.data
    } catch (error) {
        return []
    }
}


export const postCustomersSocialMedia = async (email, socialMedia) => {
    try {
        let dody = {
            customerIdentifier: email,
            socialMedia: {
                socialMedia: "LINKEDIN",
                socialMediaUrl: socialMedia.Linkding
            }
        }
        let response

        if (socialMedia.existsLinkding) {
            response = await axios.put(`${configExternalApi[environment].apiUrlCustomersSocialMedia}`, dody, {
                headers: {
                    'content-type': 'application/json',
                    "Ocp-Apim-Subscription-Key": headerProfileBack
                }
            })
        } else {
            response = await axios.post(`${configExternalApi[environment].apiUrlCustomersSocialMedia}`, dody, {
                headers: {
                    'content-type': 'application/json',
                    "Ocp-Apim-Subscription-Key": headerProfileBack
                }
            })
        }
        return response.data
    } catch (error) {

    }
}

export const getEndCourses = async (email) => {

    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-courses-finalization?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        //console.log(e)
        return []
    }

}

const getMetricsUser = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-products-metrics?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        //console.log(e)
        return []
    }
}

export const getMostRatedCompetence = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlNewLiderly}/employments/skills/users/rated`, {
            headers: {
                Authorization: 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRob3JpdGllcyI6Ilt7XCJhdXRob3JpdHlcIjpcIlJPTEVfU1lTVEVNX1JPTEVcIn1dIiwic3ViIjoieWFpcnZtdHpAZ21haWwuY29tIiwiaWF0IjoxNjkxMTg0MzkyfQ.C97L-dreY9YMeQonlsAczrqusGdVs2ASFJtR8jrWe-8UbIoYhpcBzJw5VpwDrx36tY-fiXHMMGGjFnK6o17EVg',
                "Ocp-Apim-Subscription-Key": headerProfileBack,
                'content-type': 'application/json'
            }
        })
        return response
    } catch (e) {
        return [
            {
                "skillName": "Frontend",
                "skillCount": 9,
                "skillId": "f6dbadbc-718f-4851-9f6a-8221b0a1a521"
            },
            {
                "skillName": "Ux/UI",
                "skillCount": 9,
                "skillId": "a718e8ce-88e1-4fd4-ae9a-8f6fca5813e5"
            },
            {
                "skillName": "Agilidad",
                "skillCount": 9,
                "skillId": "0dbf5e8c-bb26-4326-8663-76a86b133519"
            },
            {
                "skillName": "Fullstack",
                "skillCount": 8,
                "skillId": "c5e2a5d0-34ae-4f8b-9e5e-6bc8263d978d"
            },
            {
                "skillName": "Backend",
                "skillCount": 8,
                "skillId": "2f759b20-1c2d-4e18-8a6e-5dc5e8fb21a3"
            }
        ]
    }
}
export const getSugestion = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrationsB}/learning-hub-top-products?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })
        return response.data
    } catch (e) {
        //console.log(e)
        return []
    }

}

export const getCustomersLearningPathsProgress = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-learning-paths-progress?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        //console.log("getCustomersLearningPathsProgress response", response.data)
        return response.data
    } catch (error) {
        //console.log("getCustomersLearningPathsProgress", error)
    }
}

export const getCustomersLearningPathsProgressDetails = async (email, learningPathId) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-learning-paths-progress-details?email=${email}&learningPathId=${learningPathId}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })

        //console.log("getCustomersLearningPathsProgressDetails response", response.data)
        return response.data
    } catch (error) {
        //console.log("getCustomersLearningPathsProgressDetails:", error)
    }
}

export const getCustomersCoursesProgress = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-courses-progress?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        //console.log("getCustomersLearningPathsProgress response", response.data)
        return response.data
    } catch (error) {
        //console.log("getCustomersCoursesProgress", error)
    }
}

export const getCustomesCourseProgresDetails = async (email, courseId) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-courses-progress-details?email=${email}&courseId=${courseId}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        //console.log("getCustomesCourseProgresDetails", error)
    }
}

export const getLinkeding = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlCustomersSocialMedia}?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        /* //console.log("getLinkedingG", error) */
    }
}

export const getProfessionalInformationResume = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/professional-information-resume?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        //console.log("getProfessionalInformationResume", error)
    }
}

export const getRoutesCatalogue = async (page) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrationsB}/learning-hub-lookup-learning-paths?page=${page}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })
        return response
    } catch (error) {
        //console.log("getRoutesCatalogue", error)
        return []
    }
}
export const getCourseCatalogue = async (page) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrationsB}/learning-hub-lookup-courses?page=${page}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })
        return response
    } catch (error) {
        //console.log("getCourseCatalogue", error)
        return []
    }
}

export const getClientInformationMentoringStatistics = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/client-information-mentoring-statistics?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        ////console.log("clientInformationMentoringStatistics", response)
        return response.data
        //perfil-back/client-information-mentoring-statistics?email=${email}
    } catch (error) {
        //console.log("clientInformationMentoringStatistics", error)
        return {
            "mentoringAvailable": 0,
            "mentoringTaken": 0
        }
    }
}

export const postSigupMentor = async (email, day) => {
    try {
        let body = {
            customerEmail: email,
            requestedAt: day
        }
        let response = await axios.post(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-mentor-onboarding-request`, body, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
                //Authorization: "Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRob3JpdGllcyI6Ilt7XCJhdXRob3JpdHlcIjpcIlJPTEVfU1lTVEVNX1JPTEVcIn1dIiwic3ViIjoieWFpcnZtdHpAZ21haWwuY29tIiwiaWF0IjoxNjkxMTg0MzkyfQ.C97L-dreY9YMeQonlsAczrqusGdVs2ASFJtR8jrWe-8UbIoYhpcBzJw5VpwDrx36tY-fiXHMMGGjFnK6o17EVg"
            }
        })
        //console.log("postSigupMentor", response)
        return response.data
    } catch (error) {
        //console.log("postSigupMentor error: ", error)
    }
}

export const getCustomersCurrentSkills = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-current-skills?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
                //Authorization: "Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRob3JpdGllcyI6Ilt7XCJhdXRob3JpdHlcIjpcIlJPTEVfU1lTVEVNX1JPTEVcIn1dIiwic3ViIjoieWFpcnZtdHpAZ21haWwuY29tIiwiaWF0IjoxNjkxMTg0MzkyfQ.C97L-dreY9YMeQonlsAczrqusGdVs2ASFJtR8jrWe-8UbIoYhpcBzJw5VpwDrx36tY-fiXHMMGGjFnK6o17EVg"
            }
        })
        //console.log("getCustomersCurrentSkills", response)
        return response.data
    } catch (error) {
        //console.log("getCustomersCurrentSkills error: ", error)
    }
}

export const getEmploymentsSkillsJobsRated = async (email) => {
    try {
        let response = await axios.get(
          `${configExternalApi[environment].apiProfileBackIntegrationsB}/employments-skills-jobs-rated?email=${email}`,
          {
            headers: {
              "content-type": "application/json",
              "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
          }
        )
        //console.log("getEmploymentsSkillsJobsRated", response)
        return response.data
    } catch (error) {
        //console.log("getEmploymentsSkillsJobsRated error: ", error)
    }
}

export const getProfessionalInformationJobTopSalary = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/professional-information-job-top-salary?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        //console.log("getProfessionalInformationJobTopSalary", response)
        return response.data
    } catch (error) {
        //console.log("getProfessionalInformationJobTopSalary error: ", error)
    }
}

export const postProfessionalInformationJobTop = async (email, jobId, statusId) => {
    try {
        let body = {
            customerIdentifier: email,
            jobId,
            statusId
        }
        //console.log("postProfessionalInformationJobTop", body)

        let response = await axios.post(`${configExternalApi[environment].apiProfileBackIntegrations}/professional-information-job-top`, body, {

            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        //console.log("postProfessionalInformationJobTop error: ", error)
    }
}

export const getUserPersonalInformation = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlPersonalInformation}?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data.information
    } catch (e) {
        return {
            information: {
                country: "",
                discord: "",
                city: "",
                phone: "",
                nickName: "",
                pronouns: "",
                state: "",
                birthDate: "",
                generalAlias: ""
            }
        }
    }
}

export const getStudentSkillsCompleted = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/student-skills-completed?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })//
        return response.data
    } catch (error) {
        //console.log("getStudentSkillsCompleted error: ", error)
    }
}

export const getCourseToPromote = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlCoursePromote}?${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        //console.log("Error Curso a inpulsar", error)
    }
}

export const getStudentIssuedBadges = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/student-issued-badges?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        return []
    }
}


export const getLearningHubRecommendedProducts = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/learning-hub-recommended-products?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {

    }
}

export const postCustomersRecommendedJobSaved = async (email, job) => {
    try {
        let body = {
            email,
            resourceId: job
        }
        let response = await axios.post(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-recommended-job-saved`, body, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {

    }
}

export const putCustomersRecommendedJobUnsaved = async (email, job) => {
    try {
        let body = {
            email,
            resourceId: job
        }
        let response = await axios.put(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-recommended-job-unsaved`, body, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })

        return response.data
    } catch (error) {

    }
}

export const getPersonalConfig = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-preferences-widgets?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        return []
        /* //console.log("getPersonalConfig error: ", error) */
    }
}

export const setPersonalConfig = async (email, value, desc) => {
    try {
        //console.log(value)
        let body = {
            widgetCode: desc,
            isActive: value
        }
        let response = await axios.post(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-preferences-widgets?email=${email}`, body, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
                //Authorization: "Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRob3JpdGllcyI6Ilt7XCJhdXRob3JpdHlcIjpcIlJPTEVfU1lTVEVNX1JPTEVcIn1dIiwic3ViIjoieWFpcnZtdHpAZ21haWwuY29tIiwiaWF0IjoxNjkxMTg0MzkyfQ.C97L-dreY9YMeQonlsAczrqusGdVs2ASFJtR8jrWe-8UbIoYhpcBzJw5VpwDrx36tY-fiXHMMGGjFnK6o17EVg"
            }
        })
        //console.log("setPersonalConfig", response)
        return response.data
    } catch (error) {
        //console.log("setPersonalConfig error: ", error)
    }
}

export const getUrlForm = async (uid) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlForm}?uid=${uid}`)
        return response
    } catch (e) {
        return [
            {
                "dev": "https://dev.liderly.com/poc-buyer/simulador-buyer.html?uid="
            }
        ]

    }
}

export const getCustomersScheduledSessions = async (email) => {
    try {
        let response = await axios.get(
            `${configExternalApi[environment].apiProfileBackIntegrations}/customers-scheduled-sessions?email=${email}`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBack
                }
            }
        )
        return response.data
    } catch (error) {
        return {
            "assigned_learners": 0,
            "scheduled_session": 0
        }
    }
}


export const getMentorMonthlySessions = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrationsB}/mentor-monthly-sessions?email=${email}`, {

            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })

        return response.data
    } catch (error) {
        return []
    }
}

export const getMentorAssignedMentees = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrationsB}/mentor-assigned-mentees?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })

        return response.data
    } catch (error) {
        return []
    }
}

export const getCustomersProgressProductsMetrics = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-in-progress-products-metrics?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        return {
            "totalCourses": 0,
            "totalLearningPaths": 0,
            "totalMentoring": 0,
            "totalApplicationsJobs": 0,
            "totalBadges": 0
        }
    }
}

export const getClientInformationJobsStatistics = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/client-information-jobs-statistics?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })

        return response.data
    } catch (error) {
        return {
            "all": 0,
            "rejected": 0,
            "active": 0,
            "inProgress": 0,
            "hired": 0,
            "inactive": 0,
            "total": 0
        }
    }
}

export const getMentorEcosystemAccompanying = async (token) => {
    try {
        const headers = {
            'Ocp-Apim-Subscription-Key': headerProfileBackB,
            'X-Auth-Token': `Bearer ${token}`
        }

        const response = await axios.get(configExternalApi[environment].apiUrlEcosystemAccompanying, { headers })
        return response.data
    } catch (error) {
        console.error('Error al realizar la solicitud:', error)
    }
}

export const getMentorAssigned = async (token) => {
    try {
        const headers = {
            'Ocp-Apim-Subscription-Key': headerProfileBackB,
            'X-Auth-Token': `Bearer ${token}`
        }

        const response = await axios.get(configExternalApi[environment].apiUrlMentorAssigned, { headers })
        return response.data
    } catch (error) {
        console.error('Error al realizar la solicitud:', error)
    }
}

export const getMenteeRatingScores = async (token) => {
    try {
        const headers = {
            'Ocp-Apim-Subscription-Key': headerProfileBackB,
            'X-Auth-Token': `Bearer ${token}`
        }

        const response = await axios.get(configExternalApi[environment].apiUrlMenteeRatingScores, { headers })
        return response.data
    } catch (error) {
        console.error('Error al realizar la solicitud:', error)
    }
}

export const getHours = async (dateInit, dateEnd) => {
    
    try {
        let dataParams = {}
        dataParams.dateInit = dateInit
        dataParams.dateEnd = dateEnd
        let response = await axios.get(`${configExternalApi[environment].apiUrlMostRatedHour}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            },
            params: { ...dataParams }
        })
        return response.data
    } catch (error) {
        console.error(error)
        return {}
    }
}
export const getStudentsByMentor = async (emailMentor) => {
    try {
        let dataParams = {}
        dataParams.email = emailMentor

        let response = await axios.get(`${configExternalApi[environment].apiUrlStudentAsigned}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            },
            params: { ...dataParams }
        })
        return response.data
    } catch (error) {
        console.error(error)
        return {}
    }
}
export const getMentoringStudentMonly = async (emailStudent) => {
    try {
        let dataParams = {}
        dataParams.customerIdentifier = emailStudent

        let response = await axios.get(`${configExternalApi[environment].apiUrlStudentMentoringMonthly}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            },
            params: { ...dataParams }
        })
        return response.data.monthlyMentoring
    } catch (error) {
        console.error(error)
        return {}
    }
}

export const getMentorRating = async (emailMentor, token) => {
    try {
        let dataParams = {}
        dataParams.email = emailMentor

        let response = await axios.get(
            `${configExternalApi[environment].apiUrlMentorRating}`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBackB,
                    "X-Auth-Token": `Bearer ${token}`
                },
                params: { ...dataParams }
            }
        )
        return response.data
    } catch (error) {
        console.error(error)
        return {}
    }
}

export const getMentorAsigned = async (emailMentor, token) => {
    
    try {
        /* let dataParams = {}
        dataParams.customerIdentifier = emailMentor */
        //`${configExternalApi[environment].apiUrlAsignedMentor}?search=${emailMentor}`,

        let response = await axios.get(
            `${configExternalApi[environment].apiUrlAsignedMentor}?email=${emailMentor}`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBackB,
                    "X-Auth-Token":  `Bearer ${token}`
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(error)
        return {}
    }
}

export const getLearningHubTopProducts = async (token) => {
    try {
        let response = await axios.get(
            `${configExternalApi[environment].apiUrlLearningHubTopProducts}`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBackB,
                    "X-Auth-Token": `Bearer ${token}`
                }
            }
        )
        return response.data
    } catch (error) {
        console.error("Ocurrio el siguiente error: ", error)
        return {}
    }
}

export const getMenteeRatingAll = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrationsB}/mentee-rating-all?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })
        return response.data
    } catch (error) {
        return {
            "rating1": 0,
            "rating2": 0,
            "rating3": 0,
            "rating4": 0,
            "rating5": 0
        }
    }
}

export const getRanuing = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].menteeRanquingAll}?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })
        return response.data
    } catch (error) {
        console.error(error)
        return {}
    }
}

export const getMentorAssignedMenteesStats = async (email, token) => {
    try {
        console.log(configExternalApi[environment])
        let response = await axios.get(
            `${configExternalApi[environment].apiUrlMentorAssignedMenteesStats}?email=${email}`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBackB,
                    "X-Auth-Token":
                        `Bearer ${token}`
                }
            }
        )
        return response.data
    } catch (error) {
        console.error("Ocurrio el siguiente error: ", error)
        return []
    }
}

export const getCustomersInProgressProductsMetrics = async (email, token) => {
    try {
        let response = await axios.get(
            `${configExternalApi[environment].apiUrlCustomersInProgressProductsMetrics}?email=${email}`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBack,
                    "X-Auth-Token": `Bearer ${token}`
                }
            }
        )
        return response.data
    } catch (error) {
        console.error("Ocurrio el siguiente error: ", error)
        return {}
    }
}

export const getInactiveInformation = async (token) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlCardBlue}`, {
            headers: {
                "X-Auth-Token": `Bearer ${token}`,
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })
        return response.data
    } catch (e) {
        console.log("error", e)
    }
}

export const getLearnersTraining = async (token) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiLearnersTraining}`, {
            headers: {
                "X-Auth-Token": `Bearer ${token}`,
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        return {
            "inactive": [],
            "assigneds": [],
            "active": [],
            "finished": [],
            "withoutStarting": []
        }
    }
}

export const getEmploymentsMetrics = async () => {
    try {
        let response = await axios.get(
            `${configExternalApi[environment].apiUrlEmploymentsMetrics}`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBackB
                }
            }
        )
        console.log("getEmploymentsMetrics", response)

        return response.data
    } catch (e) {
        console.log("error getEmployetsMetrics: ", e)
        return {
            "totalJobsCoach": 0,
            "totalLearningCoach": 0,
            "totalMentorCoodinator": 0,
            "totalLearnersB2C": 0,
            "totalLearnersB2B": 0,
            "totalVocationalCoach": 0
        }
    }
}

export const getEmploymentsCompanyJobsPostsStats = async (email, token) => {
    try {
        let response = await axios.get(
            `${configExternalApi[environment].apiUrlEmploymentsCompanyJobsPostsStats}?email=${email}`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBackB,
                    "X-Auth-Token":
                        `Bearer ${token}`
                }
            }
        )
        return response.data
    } catch (error) {
        console.error("Ocurrio el siguiente error: ", error)
        return {}
    }
}

export const getCompaniesProductsStats = async (email, token) => {
    try {
        let response = await axios.get(
            `${configExternalApi[environment].apiUrlCompaniesProductsStats}?email=${email}`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBack,
                    "X-Auth-Token":
                        `Bearer ${token}`
                }
            }
        )
        return response.data
    } catch (error) {
        return {
            "courseDetails": {
                "totalLicences": 0,
                "totalLicencesUsed": 0,
                "totalLicencesInProgress": 0,
                "totalLicencesComplete": 0,
                "totalLicencesToDo": 0
            },
            "learningPathDetails": {
                "totalLicences": 0,
                "totalLicencesUsed": 0,
                "totalLicencesInProgress": 0,
                "totalLicencesComplete": 0,
                "totalLicencesToDo": 0
            },
            "mentoringDetail": {
                "totalMentoring": 0,
                "totalMentoringUsed": 0
            }
        }
    }
}

export const getCompaniesProductsPurchased = async (email, token) => {
    try {
        let response = await axios.get(
            `${configExternalApi[environment].apiUrlCompaniesProductsPurchased}?email=${email}`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBack,
                    "X-Auth-Token":
                        `Bearer ${token}`
                }
            }
        )
        return response.data
    } catch (error) {
        console.error("Ocurrio el siguiente error: ", error)
        return {
            "courses": [],
            "roads": [],
            "mentories": []
        }
    }
}

//
export const getCustomersCoursesFinalization = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlCustomersCoursesFinalization}?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        console.log("error", e)
    }
}

export const getUrlStudentIssuedBadges = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlStudentIssuedBadges}?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        console.log("error", e)
        return []
    }
}

export const getCustomersJobsRecommended = async () => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlCustomersJobsRecommended}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        console.log("error", e)
    }
}

export const getClientsInformationMentoringMentorsHistorical = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlClientsInformationMentoringMentorsHistorical}?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        console.log("error", e)
        return []
    }
}

export const getCustomersPreferencesWidgets = async () => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlCustomersPreferencesWidgets}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        console.log("error", e)
    }
}

export const getLookupWidgets = async () => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlLookupWidgets}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        console.log("error", e)
    }
}

export const getClientInformationMentoring = async (email) => {
    try {
        let response = await axios.get(
            `${configExternalApi[environment].apiProfileBackIntegrations}/client-information-mentoring?customerIdentifier=${email}`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBack
                }
            }
        )
        return response.data
    } catch (e) {
        console.log("error", e)
    }
}

export const getCompanyB2B = async (token) => {
    try {
        let response = await axios.get(
            `${configExternalApi[environment].apiProfileBackIntegrations}/companies-info`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBack,
                    "X-Auth-Token": `Bearer ${token}`
                }
            }
        )
        return response.data
    } catch (e) {
        console.log("error", e)
    }
}

export const setLookupWidgets = async (value, desc) => {
    try {
        let body = {
            widgetCode: desc,
            isActive: value
        }
        let response = await axios.post(
            `${configExternalApi[environment].apiProfileBackIntegrations}/lookup-widgets-update`,
            body,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBack
                }
            }
        )
        console.log("response", response)
        return response.data
    } catch (error) {
    }
}

export const getCompaniesProductsPurchasedStats = async (email, token) => {
    try {
        let response = await axios.get(
            `${configExternalApi[environment].apiProfileBackIntegrations}/companies-products-purchased-stats`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBack,
                    "X-Auth-Token":
                        `Bearer ${token}`
                }
            }
        )

        return response.data
    } catch (error) {
        return {
            "rutasLicenciasPendientes": 0,
            "rutasTotal": 0,
            "mentoriasDisponibles": 0,
            "rutasLicenciasUsadas": 0,
            "metoriasTotal": 0,
            "metoriasUsadas": 0,
            "cursosLicenciasUsadas": 0,
            "cursosTotal": 0,
            "rutasLicenciasTotal": 0,
            "cursosLicenciasTotal": 0,
            "cursosLicenciasPendientes": 0
        }
    }
}

export const getEmploymentsCompanyJobsPost = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlEmploymentsCompanyJobsPosts}?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })
        return response.data
    } catch (e) {
        console.log("error", e)
        return []
    }
}

export const getEmploymentsCompanyJobsProspects = async (jobId) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlEmploymentsCompanyJobsProspects}?jobId=${jobId}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })
        return response.data
    } catch (e) {
        console.log("error", e)
        return []
    }
}

export const getEmploymentsCompanyJobsProspectsV2 = async (jobId) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlEmploymentsCompanyJobsProspectsV2}?jobId=${jobId}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        console.log("error", e)
        return []
    }
}

export const getCustomersLearningPathsFinalization = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-learning-paths-finalization?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        return []
    }
}

export const getCustomersB2BCoursesProgress = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-b2b-courses-progress?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        return []
    }
}


export const getCustomersB2BLearningPathsProgress = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-b2b-learning-paths-progress?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        
        return response.data
    } catch (error) {
        return []
    }
}

export const getCustomersB2BProgressProductsMetrics = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrations}/customers-b2b-in-progress-products-metrics?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (error) {
        return {
            "totalCourses": 0,
            "totalLearningPaths": 0,
            "totalMentoring": 0,
            "totalApplicationsJobs": 0,
            "totalBadges": 0
        }
    }
}

export const getCostReport = async () => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiOmnisysIntegracion}/cost-report`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerOmnisysIntegracion
            }
        })
        return response.data
    } catch (error) {
        return {
            "month":"",
            "current":"$0",
            "estimated":"$0"
        }
    }
}

///learning/hub/recommended/products/sameroute?email=
export const getLearningRecommendedProductSameRoute = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiProfileBackIntegrationsB}/learning-hub-recommended-products-sameroute?email=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBackB
            }
        })
        console.log("getLearningRecommendedProductSameRoute", response)
        return response.data

    } catch (error) {
        return []
    }
}

export const getLearningRecommendedProductDifferentRoute = async (email) => {
  try {
    let response = await axios.get(
      `${configExternalApi[environment].apiProfileBackIntegrationsB}/learning-hub-recommended-products-differentroute?email=${email}`,
      {
        headers: {
          "content-type": "application/json",
          "Ocp-Apim-Subscription-Key": headerProfileBackB
        }
      }
    )
    console.log("getLearningRecommendedProductSameRouteDifereeeennnnnnnnnt", response)
    return response.data
  } catch (error) {
    return []
  }
}

export const getMentorEcosystem = async (token) => {
    try {
        let response = await axios.get(
            `${configExternalApi[environment].apiUrlMentorEcosystem}`,
            {
                headers: {
                    "content-type": "application/json",
                    "Ocp-Apim-Subscription-Key": headerProfileBackB,
                    "X-Auth-Token": `Bearer ${token}`
                }
            }
        )
        return response.data
    } catch (error) {
        return []
    }
}

export const userRequestsExternal = {
  getUserByEmail,
  getUserCourses,
  getUserRoutes,
  getUserStatisticsCourses,
  getUserMentoring,
  getUserHobbies,
  addUserHobbies,
  addUserInformation,
  getUserStatisticsJobs,
  getUserPreferences,
  addUserPreferences,
  getImagesDefault,
  uploadCustomerDefault,
  getProfileImageUser,
  getAchievementsUser,
  getTotalAchievementsUser,
  deleteProfileImageUser,
  getUserMentoringStatistics,
  getUserMonthlyMentoring,
  uploadCustomerResume,
  getNewCourseLiderly,
  getNewRoutesLiderly,
  saveNewCourseOrPath,
  saveMostAcquiredCourseOrPath,
  deleteNewCourseOrPath,
  deleteMostAcquiredCourseOrPath,
  getUserMentorsInfo,
  getCustomerResume,
  getMetricasUser,
  getCustomersSocialMedia,
  deleteCustomerResume,
  postCustomersSocialMedia,
  getSkills,
  getEndCourses,
  getMetricsUser,
  getMostRatedCompetence,
  getSugestion,
  getCustomersLearningPathsProgress,
  getCustomersLearningPathsProgressDetails,
  getCustomersCoursesProgress,
  getCustomesCourseProgresDetails,
  getLinkeding,
  getProfessionalInformationResume,
  getRoutesCatalogue,
  getCourseCatalogue,
  getClientInformationMentoringStatistics,
  postSigupMentor,
  getCustomersCurrentSkills,
  getEmploymentsSkillsJobsRated,
  getProfessionalInformationJobTopSalary,
  postProfessionalInformationJobTop,
  getUserPersonalInformation,
  getStudentSkillsCompleted,
  getStudentIssuedBadges,
  getLearningHubRecommendedProducts,
  postCustomersRecommendedJobSaved,
  putCustomersRecommendedJobUnsaved,
  getCourseToPromote,
  getPersonalConfig,
  setPersonalConfig,
  getUrlForm,
  getCustomersScheduledSessions,
  getMentorMonthlySessions,
  getMentorAssignedMentees,
  getCustomersProgressProductsMetrics,
  getClientInformationJobsStatistics,
  getMentorEcosystemAccompanying,
  getMentorAssigned,
  getMenteeRatingScores,
  getHours,
  getStudentsByMentor,
  getMentoringStudentMonly,
  getMentorRating,
  getMentorAsigned,
  getMentorAssignedMenteesStats,
  getCustomersInProgressProductsMetrics,
  getLearningHubTopProducts,
  getMenteeRatingAll,
  getRanuing,
  getInactiveInformation,
  getLearnersTraining,
  getEmploymentsMetrics,
  getEmploymentsCompanyJobsPostsStats,
  getCompaniesProductsStats,
  getCompaniesProductsPurchased,
  getCustomersCoursesFinalization,
  getUrlStudentIssuedBadges,
  getCustomersJobsRecommended,
  getClientsInformationMentoringMentorsHistorical,
  getCustomersPreferencesWidgets,
  getLookupWidgets,
  setLookupWidgets,
  getCompaniesProductsPurchasedStats,
  getCompaniesProductsPurchasedStats,
  getCompanyB2B,
  getUserMentorsInfoB2B,
  getEmploymentsCompanyJobsPost,
  getEmploymentsCompanyJobsProspects,
  getEmploymentsCompanyJobsProspectsV2,
  getClientInformationMentoring,
  getCustomersLearningPathsFinalization,
  getCustomersB2BCoursesProgress,
  getCustomersB2BLearningPathsProgress,
  getCustomersB2BProgressProductsMetrics,
  getCostReport,
  getLearningRecommendedProductSameRoute,
  getLearningRecommendedProductDifferentRoute,
  getMentorEcosystem
}